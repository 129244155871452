import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import './Footer.css';
import logo from '../Photos/favicon.png';
import logo1 from '../Photos/favi.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import EmailLink from './EmailLink';
import ScrollToTopButton from './ScrollToTopButton';

const Footer = () => {
    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    };
    return (
        <footer className="footer">
            <div className="row">
                <div className="column">
                    <img src={logo1} alt="Company Logo" className="logof1" />
                    <img src={logo} alt="Company Logo" className="logof2" />
                    <p className="textc">
                        Pollucare Biomedical Management Private Limited is a Private incorporated on 28 January 2004.
                    </p>
                </div>
                <div className="column ql">
                    <h3>Quick Links</h3>
                    <ul>
                        <li>
                            <Link to="/" onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/aboutus" onClick={handleLinkClick}>About Us</Link>
                        </li>
                        <li>
                            <Link to="/services" onClick={handleLinkClick}>Services</Link>
                        </li>
                        <li>
                            <Link to="/gallery" onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" onClick={handleLinkClick}>Contact Us</Link>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <h3>Helpline Numbers</h3>
                    <ul>
                        <li><a href={`tel:079-27550075`}>
                            <span className="icon">
                                ☎
                            </span>{' '}
                            079-27550075</a>
                        </li>
                        <li><a href={`tel:9428528999`}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faPhone} />
                            </span>{' '}
                            9428528999</a>
                        </li>
                        <li><a href={`tel:9974556189`}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faPhone} />
                            </span>{' '}
                            9974556189</a>
                        </li>
                        <li><a href={`tel:8401125232`}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faPhone} />
                            </span>{' '}
                            8401125232</a>
                        </li>
                        <li><a href={`tel:9979107721`}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faPhone} />
                            </span>{' '}
                            9979107721</a>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <h3>Follow Us</h3>
                    <ul>
                        <li>
                            <Link to="https://facebook.com">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </span>{' '}
                                Facebook
                            </Link>
                        </li>
                        <li>
                            <Link to="https://instagram.com">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </span>
                                Instagram
                            </Link>
                        </li>
                        <li>
                            <Link to="https://wa.me/918401125232">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </span>{' '}
                                WhatsApp
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <h3>Pollucare Biomedical Management</h3>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <a href="https://www.google.com/maps/place/Subhash+Bridge/@23.0622289,72.5810413,16.22z/data=!4m9!1m2!2m1!1s3%2F4,+2nd+Floor,+Ganga+Rachana+Complex,+Subhash+Bridge,+Ahmedabad+-+380027+!3m5!1s0x395e854f9cc51777:0x10b5ae5d99d186af!8m2!3d23.0624954!4d72.5851299!16s%2Fg%2F11p9w2lbmg?entry=ttu">

                        <p>2 Floor,3 & 4, Ganga Rachana Commercial Complex,Nr. R.B.I. Quarters,Subhash Bridge Circle, Ahmedabad - 380027  </p>
                    </a>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <EmailLink email="pollucarebm@yahoo.co.in" />
                </div>
            </div>
            <ScrollToTopButton />
            <div className="row">
                <div className="line"></div>
                <br />
                <p className="copyright">
                    &copy; {new Date().getFullYear()} Pollucare. All rights reserved.
                </p>
                <p className="made-by"><a href="https://www.linkedin.com/in/saurabh-amin/">Made by  Saurabh Amin</a></p>
            </div>
        </footer>
    );
};

export default Footer;
