import React from 'react';
import './Error.css';
const ErrorPage = () => {
    return (
        <div className="error-page">
            <h2>Oops! Page Not Found</h2>
            <p>The requested page does not exist.</p>
            <a href="/">Go to Home Page</a>
        </div>
    );
};

export default ErrorPage;
