import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import the Link and useLocation hooks
import './Navbarm.css';
import logo from '../Photos/favicon.png';
import logo1 from '../Photos/favi.png';
export default function Header() {
    const location = useLocation(); // Get the current location
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const closeNav = () => {
        setIsNavExpanded(false);
    };
    return (
        <nav className="navigation">
            <div className="logosm">
                <img src={logo1} alt="Logo" className="logo1" />
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <button
                className="hamburger"
                onClick={() => { setIsNavExpanded(!isNavExpanded) }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <line x1="3" y1="12" x2="21" y2="12" />
                    <line x1="3" y1="6" x2="21" y2="6" />
                    <line x1="3" y1="18" x2="21" y2="18" />
                </svg>
            </button>
            <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                <ul>
                    <li>
                        <Link className={location.pathname === '/' ? 'abm' : ''} to="/" onClick={closeNav}>Home</Link>
                    </li>
                    <li>
                        <Link className={location.pathname === '/aboutus' ? 'abm' : ''} to="/aboutus" onClick={closeNav}>About Us</Link>
                    </li>
                    <li>
                        <Link className={location.pathname === '/services' ? 'abm' : ''} to="/services" onClick={closeNav}>Services</Link>
                    </li>
                    <li>
                        <Link className={location.pathname === '/gallery' ? 'abm' : ''} to="/gallery" onClick={closeNav}>Gallery</Link>
                    </li>
                    <li >
                        <Link className={location.pathname === '/contact' ? 'abm' : ''} to="/contact" onClick={closeNav}>Contact Us</Link>
                    </li>
                </ul>
            </div>
        </nav >
    );
}

