import React from 'react';
import './Card.css'; // Assuming you have a CSS file for styling

const Card = ({ title, image }) => {
    return (
        <div className="card">
            <h1 className='h11'>{title}</h1>
            <div className="i1" style={{ backgroundImage: `url(${image})` }}></div>
        </div>
    );
};

export default Card;
