import React, { useState } from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import EmailLink from './EmailLink';

function Contact() {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        if (name.length === 0 || mobile.length < 10 || mobile.length > 13 || email.length === 0 || comment.length === 0) {
            alert('Enter Valid Data in the form....');
        }
        else {
            let to = "pollucarebm@yahoo.co.in";
            let message = "[Potential Customer] %0a%0a Name : " + name + "%0a Mobile Number : " + mobile + "%0a Email : " + email + "%0a Message : " + comment;
            let subject = "Enquiry about service....";
            // let url = `https://mail.google.com/mail/?view=cm&fs=1&to=${to}&su=${subject}&body=${message}`;
            let url = `mailto:${to}?subject=${subject}&body=${message}`;
            // Open our newly created URL in a new tab to send the message
            window.open(url);
        }
        setName('');
        setMobile('');
        setEmail('');
        setComment('');
    };

    return (
        <div style={{ backgroundColor: "lightblack", margin: "30px", color: "white" }}>
            <h1 className='tle' style={{ textAlign: "center" }}>Contact Us</h1>
            <div className="contact">
                <div className="column address">
                    <h2 className='det'>Contact Details</h2><br />
                    <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;&nbsp;  <b style={{ color: "lightgreen" }}> Address </b><a href="https://www.google.com/maps/place/Subhash+Bridge/@23.0622289,72.5810413,16.22z/data=!4m9!1m2!2m1!1s3%2F4,+2nd+Floor,+Ganga+Rachana+Complex,+Subhash+Bridge,+Ahmedabad+-+380027+!3m5!1s0x395e854f9cc51777:0x10b5ae5d99d186af!8m2!3d23.0624954!4d72.5851299!16s%2Fg%2F11p9w2lbmg?entry=ttu">

                        <p className='abcd'>2 Floor,3 & 4, Ganga Rachana Commercial Complex,Nr. R.B.I. Quarters,Subhash Bridge Circle, Ahmedabad - 380027 </p>
                    </a>
                    <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;<b style={{ color: "lightgreen" }}> Email </b> <p className="abcd"  ><EmailLink email="pollucarebm@yahoo.co.in" /></p>
                    <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;<b style={{ color: "lightgreen" }}> Phone </b> <p className="abcd">
                        <a href={`tel:079-27550075`}>079-27550075(☎)</a>
                        <a href='tel:+9428528999'>9428528999</a>
                        - Mehul K. Kalaria (Director)
                        <a href={`tel:9974556189`}>9974556189</a>
                        - Bhavesh Prajapati (Employee)
                        <a href={`tel:8401125232`}>8401125232</a>
                        - Amit L Solanki (Employee)
                        <a href={`tel:9979107721`}>9979107721</a>
                        - Manish U.Solanki (Plant Manager)</p>
                </div>
                <div style={{ width: "30px" }}></div>
                <div className="contact-form column">
                    <h2 className='det'>Get in Touch</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <p></p>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                placeholder='Your Name'
                                onChange={(e) => setName(e.target.value)}
                                autoComplete="given-name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile Number:</label>
                            <p></p>
                            <input
                                type="tel"
                                id="mobile"
                                value={mobile}
                                placeholder='mobilenumber'
                                onChange={(e) => setMobile(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <p></p>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                placeholder='ex.email@xyz.com'
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="comment">Comment:</label>
                            <p></p>
                            <textarea
                                id="comment"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder='Details about waste....'
                                required
                            />
                        </div>
                        <p></p>
                        <button type="submit" onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
            </div>
        </div >
    );
}

export default Contact;
