import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Slider from './desktop_pages/Slider';
import Navbar from './desktop_pages/Navbar';
import Footer from './desktop_pages/Footer';
import ContactForm from './desktop_pages/Contact';
import Gallery from './desktop_pages/Gallery';
import Services from './desktop_pages/Services';
import About from './desktop_pages/About';
import Navbarm from './mobile_pages/Navbarm';
import ErrorPage from './desktop_pages/ErrorPage';
function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the window width state when the component mounts and when window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Router>
      {windowWidth < 600 ? <Navbarm /> : <Navbar />}
      <Routes>
        <Route path="/" exact element={<Slider />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/aboutus" element={<About />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
