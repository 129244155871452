import React, { useState, useEffect } from 'react';
import styles from './ScrollToTopButton.module.css';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Add a scroll event listener to the window
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Clean up the event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        // Calculate the scroll position
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        // Show the button if the scroll position is greater than a certain threshold (e.g., 300px)
        setIsVisible(scrollPosition > 300);
    };

    const scrollToTop = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div>
            {isVisible && (
                <button className={styles.scrollToTopButton} onClick={scrollToTop}>
                    <FaArrowUp />
                </button>
            )}
        </div>
    );
};
export default ScrollToTopButton;