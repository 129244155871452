import React from 'react';
import './About.css'; // Import the CSS file
import Photo1 from '../Photos/Photo1.jpg';
import Photo2 from '../Photos/office1.jpg';
import Photo0 from '../Photos/office2.jpg';
import director from '../Photos/director.jpg';

const About = () => {
    const photos = [
        { id: 2, src: Photo2, label: '' },
        { id: 1, src: Photo1, label: '' },
        { id: 3, src: Photo0, label: '' },

        // Add more photos as needed
    ];
    const teams = [
        { id: 1, src: director, label: 'MEHUL K. KALARIA' },
        { id: 2, src: "", label: 'Amit L Solanki (Employee)' },
        { id: 3, src: "", label: 'Bhavesh Prajapati (Employee)' },
        { id: 4, src: "", label: 'Manish U.Solanki (Plant Manager)' },

        // Add more photos as needed
    ];
    return (
        <div>
            <section className="section">
                <h1 className='srvcg'>About Us </h1>
                <img src={Photo0} alt="Plant of Waste Management" className='hgg' />
            </section>
            <div className='aboutUsContainer'>
                <div className='title-box'>
                    <h2>About Us</h2>
                    <h5>In the area of waste management, Pollucare Biomedical Management Private Limited is a multifunctional business. The business began operations in 2004 with the innovative notion of preserving the environment through the treatment of biomedical waste.</h5>

                </div>
                <div className='mission'>
                    <div className='missionPart'>
                        <h3> Mission</h3>
                    </div>
                    <div className='missionData'>
                        <p>The mission of biowaste management is to minimize the environmental impact and health risks associated with the disposal and treatment of organic waste by implementing efficient and sustainable practices.</p>
                        <p>It aims to promote the recycling, composting, and safe disposal of biowaste to reduce pollution, conserve resources, and support a cleaner and healthier environment.</p>
                        <p>The mission of biowaste management is to safely and efficiently process and dispose of biological waste, minimizing environmental impact and promoting public health</p>
                    </div>
                </div>
                <div className='mission'>
                    <div className='visionPart'>
                        <h3> Vision</h3>
                    </div>
                    <div className='vissionData'>
                        <p>Encouraging active participation and awareness among communities, emphasizing the importance of segregating and properly disposing of biowaste to reduce landfill burden and promote a healthier environment.</p>
                        <p>Continuously investing in research and development to identify new methods and technologies that improve biowaste management, optimizing resource recovery and enhancing the overall efficiency of the process.</p>
                        <p>Biowaste management promotes community engagement and education to raise awareness about the benefits of proper waste segregation, composting, and recycling, fostering a culture of responsible waste management for a greener and healthier future</p>
                    </div>
                </div>
            </div>

            <section>
                <h1 className='srvc2'>Our plant</h1>
                <div className="photo-gallery">
                    {photos.map((photo) => (
                        <div className="photo-card" key={photo.id}>
                            <img className="photo" src={photo.src} alt={photo.label} />
                            <h1><div className="label">{photo.label}</div></h1>
                        </div>
                    ))}
                </div>
            </section>
            <section style={{ marginBottom: "40px", marginTop: "20px" }}>
                <h1 className='srvc2'>Our Director</h1>
                <div className="photo1-gallery">
                    {teams.map((team) => (
                        <div className="photo-card tm" key={team.id}>
                            {
                                team.src && <>
                                    <img className="photo" src={team.src} alt={team.label} />
                                    <div className='aname' key={team.id} >
                                        <div className="label">{team.label}</div>
                                    </div>
                                </>
                            }
                        </div>
                    ))}
                </div>
            </section >
            <section style={{ marginBottom: "40px", marginTop: "20px" }}>
                <h1 className='srvc2'>Our Team</h1>
                <div className="temname">
                    <h2>Amit L Solanki (Employee)</h2>
                    <h2>Bhavesh Prajapati (Employee)</h2>
                    <h2> Manish U.Solanki (Plant Incharge)</h2>
                </div>
            </section >
        </div >
    );
};

export default About;